main {
  width: min(600px, 90vw);
  margin-left: auto;
  margin-right: auto;
}

@media only screen and (max-width: 470px) {
  body {
    font-size: 0.75rem;
  }

  select {
    min-width: 75px;
  }
}
